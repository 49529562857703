import urlSet from '@/requests/url'

let loginMixin = {
  data() {
    return {
      localBaseUrl: urlSet.localUrl,
      publishBaseUrl: urlSet.baseUrl,
      disableCode: false,
      countDown: 60,
      timer: null,
      codeMessage: '发送验证码',
    }
  },
  methods: {
    allLogin: function(params) {
      return this.$http.get('/api/v1/sso/tongji/login' + params + '&type=shortName')
    },
    newUserLogin: function(username, password) {
      return this.$http.post('/api/v1/user/wwwlogin', {
        'username': username,
        'password': password
      })
    },
    userLogin: function(username, password) {
      return this.$http.post('/api/v1/user/wwwlogin', {
        'username': username,
        'password': password
      })
    },
    userRegister: function(newUser) {
      return this.$http({
        url: '/api/v1/user/register/register',
        method: 'POST',
        data: newUser
      })
    },
    sendCode: function(number) {
      return this.$http({
        url: '/api/v1/platform/sms/sendRegisterCode?phone=' + number,
        method: 'GET'
      })
    },
    logout: function() {
      return this.$http({
        url: '/api/v1/user/accountlogout',
        method: 'GET'
      })
    },
    passInput(val) {
      // 如果输入框为空
      if (!val.length) {
        this.percentage = 0;
      // 如果小于六位
      } else if (val.length < 6) {
        this.setColor('#ff0000', 20)
      } else if (val.length >= 6) {
        // 大写字母
        let upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let upperCount = 0;
        for (let s in val) {
          if ((upper.indexOf(val.charAt(s))) != -1) {
            upperCount += 1;
          }
        }
        // 特殊符号
        let symbol = '~!?`<>;@#$%^&*()_+-=""/.,';
        let symbolCount = 0;
        for (let s in val) {
          if ((symbol.indexOf(val.charAt(s))) != -1) {
            symbolCount += 1;
          }
        }
        if (symbolCount && upperCount) {
          this.setColor('#0fdf54', 100);
        } else if (upperCount || symbolCount) {
          this.setColor('#5cb87a', 80);
        } else if (!parseInt(val)) {
          this.setColor('#f56c6c', 40);
        } else if (parseInt(val).toString() == val) {
          this.setColor('#f56c6c', 40);
        } else {
          this.setColor('#e6a23c', 60);
        }
      }
    },
    setColor(color, percent) {
      this.passColor = color;
      this.percentage = percent;
    },
    format(percentage) {
      switch (percentage) {
        case 0:
          return ''
        case 20:
          return '过短'
        case 40:
          return '较低'
        case 60:
          return '普通'
        case 80:
          return '较高'
        case 100:
          return '极高'
      }
    },
    numberInput(val) {
      if (isNaN(parseInt(val))) {
        this.newUser.tel = '';
        return false;
      }
      this.newUser.tel = parseInt(val).toString();
    },
    numberFindInput(val) {
      if (isNaN(parseInt(val))) {
        this.findPass.tel = '';
        return false;
      }
      this.findPass.tel = parseInt(val).toString();
    }
  },
  getCode() {
    this.codeMessage = '';
    this.disableCode = true;
    this.countDown = 60;
    this.timer = setInterval(()=>{
      this.countDown--
      if(this.countDown===0) {
        this.disableCode = false
        clearInterval(this.timer)
        this.codeMessage = '发送验证码';
      }
    }, 1000)
  },
}

class NewUser {
  constructor() {
    this.tel = '';
    this.checkcode = '';
    this.password = '';
    this.userid = '';
    this.requirepassword = '';
  }
}

export { loginMixin, NewUser };