<template>
  <div class="top">
    <div class="content">
      <div class="tabs">
        <span @click="tabClick('平台首页')">平台首页</span>
        <span class="active" @click="tabClick('资源中心')">资源中心</span>
        <span @click="tabClick('知识中心')">知识中心</span>
        <span @click="tabClick('资讯及书展')">资讯及书展</span>
        <span @click="tabClick('机构采选')">机构采选</span>
        <span @click="tabClick('支付服务')">支付服务</span>
      </div>
      <div v-if="isLogin" class="login">
        <span @click="toCenter">个人中心</span>
        <span class="line">|</span>
        <!-- <el-popover class="popover" trigger="hover" width="220">
          <user-info/>
          <span slot="reference">个人中心</span>
        </el-popover> -->
        <span @click="confirmLogout">退出</span>
      </div>
      <div v-else class="login">
        <span @click="doLogin">登录</span>
        <span class="line">|</span>
        <span @click="doSignUp">注册</span>
      </div>
    </div>
    <login-menu :showLogin="showLogin" :tab="loginTab" @close-dialog="closeDialog" />
  </div>
</template>

<script>
import LoginMenu from 'views/login/LoginMenu'
import urlSet from '@/requests/url'

export default {
  name: '',
  components: {
    LoginMenu,
  },
  props: {},
  computed: {
    isLogin() {
      const token = localStorage.getItem('token')
      if (token) return true
      else return false
    },
  },
  data() {
    return {
      tabs: ['平台首页', '资源中心', '知识中心', '资讯及书展', '机构采选'],
      activeIdx: 1,
      showLogin: false,
      loginTab: 0,
      customercode: '',
    }
  },
  created() {
    this.customercode = localStorage.getItem('customerCode')
    console.log(this.customercode)
  },

  methods: {
    showPick(index) {
      const code = this.customercode
      if (index === 4) {
        if (!code && code == 1) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
    tabClick(tab) {
      let shortName = localStorage.getItem('shortname')
      const token = localStorage.getItem('token')
      switch (tab) {
        case '平台首页':
          location.href = urlSet.baseUrl + '/index/#/managerhome'
          break
        case '资源中心':
          this.$router.push('home')
          break
        case '知识中心':
          location.href = urlSet.baseUrl + '/kc/#/home'
          break
        case '资讯及书展':
          location.href = urlSet.baseUrl + '/ic/#/ibhome'
          break
        case '机构采选':
          window.open(`${urlSet.baseUrl}/xs/#/sphome`)
          break
        case '支付服务':
          window.open(`${urlSet.baseUrl}/index/#/pay`)
          break
      }
    },
    doLogin() {
      this.loginTab = 0
      this.showLogin = true
    },
    doSignUp() {
      this.loginTab = 1
      this.showLogin = true
    },
    closeDialog() {
      this.showLogin = false
    },
    toCenter() {
      window.open('/my/#/')
    },
    // 确认是否退出
    confirmLogout() {
      this.$confirm('确定要退出吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.doLogout()
        })
        .catch(() => {
          return false
        })
    },
    doLogout() {
      this.$http.get('/api/v1/user/accountlogout').then((res) => {
        if (res.cxajaxrc == 0) {
          this.$message.success('您已成功退出')
          localStorage.clear()
          location.reload()
        }
      })
    },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
.top {
  background-color: $mainColor;
  .content {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    color: #fff;
    .tabs {
      display: flex;
      line-height: 45px;
      span {
        // margin: 0 10px;
        display: inline-block;
        width: 105px;
        cursor: pointer;
        text-align: center;
        &.active {
          margin: 0;
        }
        &:first-child {
          margin-left: 0;
        }
      }
    }
    .login {
      line-height: 45px;
      span {
        position: relative;
        cursor: pointer;
        &:nth-of-type(1) {
          margin-right: 10px;
        }
        &:nth-of-type(3) {
          margin-left: 10px;
        }
      }
      .line {
        padding: 0 5px;
        color: #fff;
      }
    }
    .active {
      background-color: #fff;
      color: $mainColor;
    }
    .popover {
      padding: 0;
    }
  }
}
</style>
