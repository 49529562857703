<template>
  <div class="bottom-info">
    <div class="top">
      <a :href="aboutPath('about')" target="_blank"><span>关于我们</span></a>
      <a :href="aboutPath('contactUs')" target="_blank"><span>联系我们</span></a>
      <a :href="aboutPath('disclaimer')" target="_blank"><span>免责声明</span></a>
      <span>本站学术标签</span>
      <span style="cursor: pointer" @click="toDetail('clc')">本站分类法</span>
      <a href="/kc/#/BigData" target="_blank"><span>本站大数据</span></a>
    </div>
    <p class="bottom">
      <span>
        版权所有 北京中科进出口有限责任公司
        <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">
          京ICP备14031295号-5
        </a>
      </span>
      &nbsp;
      <span>技术支持 江苏畅想之星信息技术有限公司</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
  methods: {
    toDetail(val) {
      if (val == 'clc') {
        window.location.href = '/kc/#/homeStationClassify'
      }
    },
    aboutPath(type) {
      return `/kc/#/BottomInfo?type=${type}`
    },
  },
}
</script>

<style lang="scss" scoped>
.bottom-info {
  width: 100%;
  background: #333;
  color: #999;
  text-align: center;
  padding: 20px 0;
  .top {
    width: 620px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  a {
    color: #999;
    text-decoration: none;
  }
}
a:hover {
  color: $mainColor;
}
</style>
