import Axios from 'axios'
import urlSet from './url'
import { Message } from 'element-ui'

const token = localStorage.getItem('token')

const request = Axios.create({
  baseURL: urlSet.baseUrl,
  timeout: 200000,
  headers: {
    Authorization: token
  }
})

request.interceptors.response.use(res => {
  if (res.status === 200 && res.data.cxajaxrc !== 0) {
    Message.error(res.data.cxajaxerrmsg)
  }
  return res.data
})

export default request