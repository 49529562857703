const host = window.location.host
//* 如果想要在本地调试线上环境，手动取消下面这行代码的注释：
// const isProd = true;
const isProd = host === 'www.knowledgesource.cn';
//! 但一定要在推送代码/打包时改回到上面这行代码！

const baseUrl = isProd ? 'https://www.knowledgesource.cn' : 'https://zkcx.cxstar.cn'
const fileUrl = isProd ? 'https://file.knowledgesource.cn' : 'https://file.cxstar.cn'

export default { baseUrl, fileUrl }
