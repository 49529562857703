<template>
  <div id="app">
    <template v-if="$route.path !== '/AboutUs'">
      <top-bar/>
      <top-header/>
    </template>
    <keep-alive :exclude="['BookList', 'DataList', 'AdvancedSearch']">
      <router-view :key="$route.fullPath" class="container"/>
    </keep-alive>
    <bottom-info v-if="$route.path !== '/AboutUs'"/>
  </div>
</template>

<script>
import TopBar from 'components/TopBar'
import TopHeader from 'components/TopHeader'
import BottomInfo from 'components/BottomInfo'

export default {
  components: {
    TopBar,
    TopHeader,
    BottomInfo,
  }
}
</script>

<style lang="scss">
  #app {
    --main-color: #2f8bd6;
  }
  .container {
    min-height: calc(100vh - 462px);
  }
</style>