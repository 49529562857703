<template>
  <div class="top-header">
    <div class="top">
      <div class="logo">
        <img @click="router2Link" :src="logo" />
      </div>
      <div class="search">
        <div class="search_border">
          <div class="search-input">
            <el-input
              v-model="searchValue"
              maxlength="100"
              @keyup.enter.native="doSearch"
              clearable
              :placeholder="inputHolder"
            />
          </div>
          <div class="search-options">
            <el-select v-model="searchOption">
              <el-option
                v-for="type in types"
                :key="type.value"
                :value="type.value"
                :label="type.name"
              />
            </el-select>
            <i class="icon-search" @click="doSearch" />
          </div>
        </div>
        <span @click="$router.push('search')">高级检索</span>
      </div>
      <div class="radios" style="display: none">
        <el-radio-group v-model="radio" v-if="haveRadio">
          <el-radio
            v-for="option in options"
            :key="option.label"
            :label="option.value"
            :value="option.value"
            size="medium"
          >
            {{ option.label }}
          </el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="menutab">
      <div class="tabs">
        <span
          v-for="(tab, idx) in tabs"
          :key="tab"
          :class="{ active: activeIdx == idx }"
          @click="tabClick(idx)"
        >
          {{ tab }}
        </span>
      </div>
    </div>
    <div class="main">
      <div class="content">
        <h1>{{ title }}</h1>
      </div>
    </div>
    <div class="fixed_xx">
      <div class="xx_top">
        <img src="../assets/images/fixed1.png" />
      </div>
      <div class="xx_center">
        <div class="phone">
          <img src="../assets/images/fixed2.png" />
          <div class="phone_content">
            <img src="../assets/images/fixed4.png" />
          </div>
        </div>

        <div class="ewm">
          <img src="../assets/images/fixed3.png" />
          <div class="wem_content">
            <img src="../assets/images/fixed5.png" />
            <p>扫一扫 添加微信</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import searchOptions from 'assets/js/searchOptions'

export default {
  name: '',
  data() {
    return {
      logo: require('@/assets/images/logo-new.png'),
      header: require('@/assets/images/header.png'),
      tabs: ['首页', '图书', '期刊', '数据库'],
      title: '全球外文学术资源（纸电图书、期刊、数据库）一站式发现平台',
      activeIdx: 0,
      radio: '',
      options: [],
      types: [
        {
          name: '图书',
          value: 'book',
        },
        {
          name: '数据库',
          value: 'data',
        },
      ],
      searchValue: '',
      searchOption: '',
    }
  },
  computed: {
    inputHolder() {
      let str = '请输入要检索的'
      if (this.searchOption === 'book') {
        return str + '图书'
      } else {
        return str + '数据库'
      }
    },
    haveRadio() {
      if (this.searchOption === 'book') {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    router2Link() {
      this.$router.push({
        name: 'Home',
      })
    },
    doSearch() {
      if (this.searchValue.substring(0, 3) === '978') {
        this.searchValue = this.lodash.replace(this.searchValue, /-/g, '')
      }
      let val = this.searchValue.trim()
      let searchObj = {
        word: val,
        name: val,
        title: '自定义条件',
        fieldname: this.radio,
        logicalop: 'and',
        relationop: 'like',
      }
      if (this.searchOption != 'book') {
        searchObj.fieldname = '10100001,10110001'
      }
      const searchFilter = {
        classify: [],
        top: [searchObj],
        left: [],
        search: [],
      }
      sessionStorage.setItem('searchFilter', JSON.stringify(searchFilter))
      const name = this.$route.name
      if (this.searchOption === 'book') {
        if (name == 'BookList') {
          this.$bus.$emit('refreshBookList')
        }
        this.$router.push('BookList')
      } else {
        if (name == 'DataList') {
          this.$bus.$emit('refreshDataList')
        }
        this.$router.push('DataList')
      }
      // this.searchValue = ''
    },
    tabClick(index) {
      this.activeIdx = index
      this.searchValue = ''
      switch (index) {
        case 0:
          this.$router.push('Home')
          break
        case 1:
          this.$router.push('BookList')
          break
        case 3:
          this.$router.push('DataList')
          break
      }
    },
    setType() {
      let name = this.$route.name
      if (name === 'DataList') {
        this.searchOption = this.types[1].value
      } else {
        this.searchOption = this.types[0].value
      }
    },
  },
  watch: {
    $route: {
      handler(route) {
        this.activeIdx = route.meta.topIndex
        this.setType()
      },
      deep: true,
    },
  },
  mounted() {
    this.options = searchOptions
    this.radio = searchOptions[0].value
    // this.setType()
  },
}
</script>

<style lang="scss" scoped>
.top-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  .top {
    height: 90px;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .logo {
      display: flex;
      align-items: flex-end;
      cursor: pointer;
      img {
        height: 50px;
      }
    }
    .search {
      display: flex;
      justify-content: center;
      align-items: center;
      .search-input {
        width: 350px;
        /deep/ .el-input__inner {
          height: 44px;
          border-radius: 0;
          border: none;
          font-size: 1rem;
        }
        /deep/ .el-input__clear {
          font-size: 1.1rem;
          padding-top: 2px;
        }
      }
      .search-options {
        background: #fff;
        color: #333;
        display: flex;
        align-items: center;
        height: 40px;
        /deep/ .el-select {
          border-bottom: 1px solid #a5ceef;
          i {
            color: #333;
          }
        }
        /deep/ .el-input__inner {
          width: 100px;
          border: none;
          font-size: 1rem;
          font-weight: bold;
          color: #333;
        }
        i {
          font-size: 1.5rem;
          font-weight: bold;
          padding: 0 20px;
          cursor: pointer;
        }
      }
      span {
        font-size: 1rem;
        margin-left: 20px;
        cursor: pointer;
        color: #2f8bd6;
      }
      .search_border {
        display: flex;
        border: 1px solid #a5ceef;
      }
      .icon-search {
        background-image: url('~@/assets/images/jsicon.png');
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        background-position-x: 12px;
        &:before {
          content: '';
        }
      }
    }
  }
  .menutab {
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .tabs {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 373px;
    font-size: 1.2rem;
    span {
      padding: 10px 25px;
      cursor: pointer;
      &:hover {
        // border-bottom: 3px solid $mainColor;
        background: $mainColor;
        color: #fff;
      }
    }
    .active {
      background: $mainColor;
      color: #fff;
    }
  }
  .main {
    width: 100%;
    background-image: url('~@/assets/images/zyzx-banner.png');
    background-size: 100% 100%;
    .content {
      width: 1200px;
      margin: 0 auto;
      padding: 40px;
      text-align: center;
      color: #fff;
      h1 {
        font-size: 2rem;
        margin-bottom: 40px;
        padding-right: 30px;
      }

      .radios {
        padding-right: 80px;
      }
      .el-radio-group {
        /deep/ .el-radio__label {
          color: #fff;
          font-size: 1rem;
        }
        /deep/ .el-radio__inner {
          border-color: #fff;
          background-color: #fff;
          transform: scale(1.2);
          &:after {
            width: 7px;
            height: 7px;
            background-color: #666;
          }
        }
      }
    }
  }
  .fixed_xx {
    position: fixed;
    height: 270px;
    right: 50px;
    top: 50%;
    width: 100px;
    .xx_top {
      margin-left: -5px;
      margin-bottom: 5px;
    }
    .xx_center {
      width: 90px;
      height: 180px;
      background: #33b16a;
      div {
        width: 70px;
        margin: 0px 10px;
        height: 89px;
        img {
          margin-top: 20px;
        }
      }
      .ewm {
        &:hover {
          .wem_content {
            display: block;
          }
        }
      }
      .phone {
        border-bottom: 1px solid #fff;
        &:hover {
          .phone_content {
            display: block;
          }
        }
      }
      .wem_content,
      .phone_content {
        background: #33b16a;
        border-radius: 3px;
        box-shadow: 0.3px 0px 8px -2px #0d0000;
        position: relative;
        z-index: 999;
        text-align: center;
        display: none;
      }
      .phone_content {
        width: 280px;
        height: 91px;
        margin-left: -200px;
        margin-top: -78px;
        img {
          margin: 31px 15px;
        }
      }
      .wem_content {
        margin-left: -230px;
        margin-top: -167px;
        width: 220px;
        height: 220px;
        text-align: center;
        p {
          padding-top: 5px;
          color: #fff;
        }
        img {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
