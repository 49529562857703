import Vue from 'vue'
import App from './App.vue'
import router from './router'
import request from './requests'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './element-variables.scss'
import 'assets/styles/init.scss'
import 'assets/fonts/iconfont.css'
import 'normalize.css'
ElementUI.Dialog.props.closeOnClickModal.default = false
ElementUI.MessageBox.setDefaults({
  closeOnClickModal: false,
})
const messages = ['success', 'warning', 'info', 'error']
messages.forEach((type) => {
  ElementUI.Message[type] = (options) => {
    if (typeof options === 'string') {
      options = {
        message: `<span style='font-size:22px;'>${options}</span>`,
      }
      // 默认配置
      options.dangerouslyUseHTMLString = true
      options.offset = 400
      options.duration = 3000
      options.showClose = false
    }
    options.type = type
    return ElementUI.Message(options)
  }
})
Vue.config.productionTip = false
Vue.prototype.$http = request
Vue.prototype.$bus = new Vue()

import lodash from 'lodash'
Vue.prototype.lodash = lodash
import qs from 'qs'
Vue.prototype.$qs = qs

import methods from './globalMethods'
Vue.prototype.$methods = methods

import Sticky from 'vue-sticky-directive'
Vue.use(Sticky)
Vue.use(ElementUI)

import globalComponents from './globalComponents'
Vue.use(globalComponents)

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
