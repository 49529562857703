export default [
  {
    label: '全部',
    value: '10100001,10110001,10120001,125,126,127,15900011,15900001,129,10400001,109,281450001,888000009,104,888000010'
  }, {
    label: '题名',
    value: '10100001,10110001,10120001'
  }, {
    label: '作者信息',
    value: '15900011'
  }, {
    label: '出版社',
    value: '10400001'
  }, {
    label: 'ISBN',
    value: '281450001'
  }, {
    label: '关键词',
    value: '10600001'
  }
]