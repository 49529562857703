<template>
  <div class="login-menu">
    <el-dialog :close-on-click-modal="false" :visible.sync="formVisible" @close="closeDialog" class="login-dialog" width="25%" center>
      <el-tabs v-model="activeTab" @tab-click="tabClick" :stretch=true>
        <!-- TAB -->
        <el-tab-pane label="用户登录" name="login" v-if="!findPassword">
          <el-input v-model="username" clearable placeholder="请输入用户名"></el-input>
          <el-input v-model="password" clearable placeholder="请输入用户密码" @keyup.enter.native="doLogin()" show-password></el-input>
          <el-button type="primary" style="width: 100%;" @click="doLogin()">登录</el-button>
          <div class="password-actions">
            <el-checkbox v-model="rememberPassword">记住密码</el-checkbox>
            <a href="javascript:void(0)" class="find" @click="findPassword = true">找回密码</a>
          </div>
          <div class="to-register" v-if="showTab">
            <p>立即去 <a href="javascript:void(0)" @click="activeTab = 'signUp'">注册</a></p>
          </div>
        </el-tab-pane>
        <!-- TAB -->
        <el-tab-pane label="找回密码" name="login" v-else>
          <div class="el-tab-pane" v-if="stepOne">
            <el-input v-model="findPass.tel" :maxlength="11" @input="inputNumber(true)" placeholder="手机号"></el-input>
            <el-input v-model="findPass.checkcode" placeholder="验证码">
              <template slot="append">
                <el-button @click="sendFindCode" :disabled="disableFind">
                  {{ codeMessage ? codeMessage : countDown.toString() + 's' }}
                </el-button>
              </template>
            </el-input>
            <el-button type="primary" style="width: 100%;" @click="doFindPass()">下一步</el-button>
            <div class="password-actions" style="float: right; padding-right: 8px;">
              <p>已有帐户？请 <a href="javascript:void(0)" @click="findPassword = false" class="pass-login">登录</a></p>
            </div>
          </div>
          <div class="el-tab-pane" v-else>
            <el-input
              class="check-pass"
              v-model="findPass.password"
              @input="passInput"
              @focus="checkPass = true"
              @blur="checkPass = false"
              @keyup.native="trimLR"
              placeholder="请输入至少6位的密码"
              maxlength="30"
              show-password
              clearable
            />
            <div>
              <el-progress v-show="checkPass" :percentage="percentage" :color="passColor" :format="format"/>
            </div>
            <el-input v-model="findPass.requirepassword" placeholder="确认密码" clearable show-password></el-input>
            <el-button type="primary" style="width: 100%;" @click="restPass()">重置密码</el-button>
            <div class="password-actions" style="float: right; padding-right: 8px;">
              <p>已有帐户？请 <a href="javascript:void(0)" @click="findPassword = false">登录</a></p>
            </div>
          </div>
        </el-tab-pane>
        <!-- TAB -->
        <el-tab-pane label="统一认证" name="uniteLogin" v-if="showTab && false">
          <el-select
            v-model="selectedUniversity"
            class="choose-school"
            placeholder="请搜索或选择学校"
            filterable
            @change="changeSelect"
          >
            <el-option
              v-for="item in universities"
              :key="item.ruid"
              :label="item.name"
              :value="item.ruid"
            ></el-option>
          </el-select>
          <el-button 
            type="primary"
            style="width: 100%;"
            @click="getJumpLink()"
            class="start-verify"
            :disabled="notSupported"
          >开始认证</el-button>
          <p v-show="notSupported" class="not-supported">当前学校暂不支持统一认证</p>
        </el-tab-pane>
        <!-- TAB -->
        <el-tab-pane label="新用户注册" name="signUp" v-if="showTab">
          <el-input v-model="newUser.tel" :maxlength="11" @input="inputNumber" placeholder="手机号"></el-input>
          <el-input v-model="newUser.checkcode" placeholder="验证码">
            <template slot="append">
              <el-button @click="doSendCode" :disabled="disableCode">
                {{ codeMessage ? codeMessage : countDown.toString() + 's' }}
              </el-button>
            </template>
          </el-input>
          <el-input v-model="newUser.userid" @blur="blurUsername" placeholder="请输入6到30位用户名(数字,字母,下划线)" clearable></el-input>
          <el-input
            class="check-pass"
            v-model="newUser.password"
            @input="passInput"
            @focus="checkPass = true"
            @blur="checkPass = false"
            @keyup.native="trimLR"
            placeholder="请输入至少6位的密码"
            maxlength="30"
            show-password
            clearable
          />
          <div>
            <el-progress v-show="checkPass" :percentage="percentage" :color="passColor" :format="format"/>
          </div>
          <el-input v-model="newUser.requirepassword" placeholder="确认密码" clearable show-password></el-input>
          <el-button @click="doRegister()" type="primary" style="width: 100%;">注册</el-button>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
import { loginMixin, NewUser } from './login'

require('./tab.css')

export default {
  props: {
    showLogin: {
      type: Boolean,
      default: false
    },
    tab: {
      type: Number,
      default: 0
    }
  },
  watch: {
    'showLogin': {
      handler(val) {
        this.formVisible = val
      },
      deep: true
    },
    tab(val) {
      if (val == 1) {
        this.activeTab = 'signUp'
      } else {
        this.activeTab = 'login'
      }
    }
  },
  data() {
    return {
      profileImg: require('@/assets/images/header.png'),
      funcMenu: ['我的建购', '机构关联', '修改密码', '个人资料', '订单管理'],
      notLogin: true,
      formVisible: false,
      activeTab: 'login',
      username: '',
      password: '',
      rememberPassword: false,
      newUser: null,
      userInfo: null,
      hoverMenu: true,
      percentage: 0,
      passColor: '',
      checkPass: false,
      selectedUniversity: '',
      universities: [],
      findPassword: false,
      findPass: {
        tel: '',
        checkcode: '',
        password: '',
        requirepassword: ''
      },
      stepOne: true,
      notSupported: false,
      shortName: '',
      showTab: true,

      disableCode: false,
      countDown: 60,
      timer: null,
      codeMessage: '发送验证码',
      disableFind: false,
    }
  },
  computed: {
    userName() {
      let name = this.userInfo.name;
      if (!name) return '';
      if (name.length > 7) return name.substring(0, 6) + '...';
      else return name
    }
  },
  methods: {
    closeDialog() {
      this.$emit('close-dialog')
    },
    inputNumber(find) {
      let tel;
      if (find === true) tel = this.findPass.tel;
      else tel = this.newUser.tel;
      if (!tel || tel.length < 11) return false;
      this.checkDuplicate('tel', tel).then(res => {
        if (find === true) {
          if (res.cxajaxrc === 0) {
            this.disableFind = true;
            this.$message.error('您输入的手机号尚未注册账号');
          } else {
            this.disableFind = false;
          }
        } else {
          if (res.cxajaxrc != 0) {
            this.disableCode = true;
            this.disableRegister = true;
            // this.$message.error(res.cxajaxerrmsg);
          } else {
            this.disableCode = false;
            this.disableRegister = false;
          }
        }
      });
    },
    blurUsername() {
      let username = this.newUser.userid;
      if (!username || username.length < 6) return false;
      this.checkDuplicate('userid', username).then(res => {
        if (res.cxajaxrc != 0) {
          this.disableRegister = true;
        } else {
          this.disableRegister = false;
        }
      });
    },
    checkDuplicate(type, val) {
      return this.$http({
        url: `/api/v1/user/register/userIdOrTelCheck?${type}=${val}`,
        method: 'GET'
      })
    },
    doLogin() {
      if (!this.username) {
        return this.$message.error('请输入用户名');
      }
      if (!this.password) {
        return this.$message.error('请输入密码');
      }

      this.userLogin(this.username, this.password).then(res => {
        console.log(res)
        if (res.cxajaxrc == 0) {
          this.$message.success('登录成功！');
          localStorage.setItem('token', res.returnvalue.token);
          localStorage.setItem('customerCode', res.returnvalue.customerCode);
          localStorage.setItem('customercode', res.returnvalue.customerCode);
          localStorage.setItem('userruid', res.returnvalue.userRuid);
          if (this.rememberPassword) {
            localStorage.setItem('username', this.username);
            localStorage.setItem('password', this.password);
          } else {
            localStorage.removeItem('username', this.username);
            localStorage.removeItem('password', this.password);
          }
          this.getShortname(res.returnvalue.customerCode)
          // this.getUserInfo()
        } else {
          // this.$message.error(res.cxajaxerrmsg);
        }
      })
    },
    getShortname(code) {
      const body = {"field":"ssoname,loginconfig,shortname,logintype,allowregister,ruid,name,type,tel,provinceid,logo,domainname,desc,subject,reader,cityid,wantbuyflag,welcome","filter":{"itemList":[{"fieldName":"customercode","logicalOperator":"and","relationOperator":"equal","searchWord":code,"valueType":"S"}]},"pageno":0,"pagesize":10,"type":"customer"}
      this.$http.post('/api/v1/platform/customer/objectlist', body).then(res => {
        if (res.cxajaxrc == 0) {
          const shortname = res.returnvalue.recordlist[0].shortname
          localStorage.setItem('shortname', shortname ? shortname : '')
        }
        location.reload()
      }).catch(err => {
        console.log(err)
        location.reload()
      })
    },
    getUserInfo() {
      this.$http.get('/api/v1/user/personaldata/getHomePageData')
      .then(res => {
        if (res.cxajaxrc != 0) {
          return
          // return this.$message.error(res.cxajaxerrmsg)
        }
        const info = res.returnvalue.homePageUserInfo;
        this.userInfo = info;
        this.hoverMenu = true;
      })
    },
    doRegister() {
      const uPattern = /^[a-zA-Z0-9_]{6,30}$/;
      if (!this.newUser['tel']) {
        return this.$message.error('请输入手机号')
      } else if (this.newUser['tel'].length != 11) {
        return this.$message.error('您输入的手机号不是11位')
      } else if (!this.newUser['checkcode']) {
        return this.$message.error('请输入验证码')
      } else if (!this.newUser['userid']) {
        return this.$message.error('请输入用户名')
      } else if(!uPattern.test(this.newUser['userid'])) {
        return this.$message.error('用户名只能由长度为6到30位的字母、数字、下划线组成')
      } else if (!this.newUser['password']) {
        return this.$message.error('请输入密码')
      } else if (this.newUser['password'].length < 6) {
        return this.$message.error('您输入密码长度过短');
      } else if (!this.newUser['requirepassword']) {
        return this.$message.error('请输入确认密码')
      } else if (this.newUser['password'] != this.newUser['requirepassword']) {
        return this.$message.error('您输入的两次密码不一致');
      }
      let reg = new RegExp("[\\u4E00-\\u9FFF]+","g");
      if (reg.test(this.newUser['userid'])) {
        return this.$message.error('用户名不能包含中文');
      }
      this.userRegister(this.newUser).then(res => {
        console.log(res);
        if (res.cxajaxrc == 0) {
          this.$message.success('注册成功！');
          this.newUser = new NewUser();
          this.activeTab = 'login';
        }
      })
    },
    doSendCode() {
      if (this.newUser['tel'].length != 11) {
        return this.$message.error('您输入的手机号不是11位')
      } else if (parseInt(this.newUser['tel']).toString().length != 11) {
        return this.$message.error('您输入的手机号包含非法字符')
      }
      this.sendCode(this.newUser['tel']).then(res => {
        console.log(res);
        if (!res.returnvalue) {
          return this.$message.error('发送失败');
        } else if (res.cxajaxrc == 0) {
          this.$message.success('验证码已发送');
          this.getCode();
        }
      })
    },
    trimLR() {
      this.newUser.password = this.newUser.password.replace(/^\s+|\s+$/gm, '')
    },
    sendFindCode() {
      if (!this.findPass['tel']) {
        return this.$message.error('请输入手机号')
      } else if (this.findPass['tel'].length != 11) {
        return this.$message.error('您输入的手机号不是11位')
      }
      this.$http({
        url: '/api/v1/platform/sms/sendFindPwdCode?phone=' + this.findPass.tel,
        method: 'GET'
      }).then(res => {
        if (res.cxajaxrc == 0) {
          this.$message.success('验证码已发送');
          this.getFindCode();
        }
      })
    },
    getFindCode() {
      this.codeMessage = '';
      this.disableFind = true;
      this.countDown = 60;
      this.timer = setInterval(()=>{
        this.countDown--
        if(this.countDown === 0) {
          this.disableFind = false
          clearInterval(this.timer)
          this.codeMessage = '发送验证码';
        }
      }, 1000)
    },
    getCode() {
      this.codeMessage = '';
      this.disableCode = true;
      this.countDown = 60;
      this.timer = setInterval(()=>{
        this.countDown--
        if(this.countDown === 0) {
          this.disableCode = false
          clearInterval(this.timer)
          this.codeMessage = '发送验证码';
        }
      }, 1000)
    },
    doFindPass() {
      if (!this.findPass['tel']) {
        return this.$message.error('请输入手机号')
      } else if (this.findPass['tel'].length != 11) {
        return this.$message.error('您输入的手机号不是11位')
      } else if (!this.findPass['checkcode']) {
        return this.$message.error('请输入验证码')
      }
      let postBody =   {
        "tel": this.findPass['tel'],
        "checkcode": this.findPass['checkcode']
      }
      this.$http({
        url: '/api/v1/user/register/findpassword',
        method: 'POST',
        data: postBody
      }).then(res => {
        if (res.cxajaxrc == 0) {
          this.findPass.ruid = res.returnvalue;
          this.stepOne = false;
        } else {
          // this.$message.error(res.cxajaxerrmsg)
        }
      })
    },
    restPass() {
      if (!this.findPass['password']) {
        return this.$message.error('请输入密码')
      } else if (this.findPass['password'].length < 6) {
        return this.$message.error('您输入密码长度过短');
      } else if (!this.findPass['requirepassword']) {
        return this.$message.error('请输入确认密码')
      } else if (this.findPass['password'] != this.findPass['requirepassword']) {
        return this.$message.error('您输入的两次密码不一致');
      }
      let postBody = {
        "ruid": this.findPass['ruid'],
        "password": this.findPass['password'],
        "requirepassword": this.findPass['requirepassword']
      }
      this.$http({
        url: '/api/v1/user/register/resetpassword',
        method: 'POST',
        data: postBody
      }).then(res => {
        if (res.cxajaxrc == 0) {
          this.$message.success('密码重置成功');
          this.findPassword = false;
          this.stepOne = false;
          this.passColor = '';
          this.percentage = 0;
        }
      })
    },
    getCustomers() {
      this.$http({
        url: '/api/v1/login/getAllCustomers?ssoOnly=true',
        method: 'GET'
      }).then(res => {
        this.universities = res.returnvalue;
      })
    },
    changeSelect(id) {
      const selectedItem = this.universities.find(item => {
        return item.ruid === id;
      });
      const shortName = selectedItem.shortname;
      if (shortName) {
        this.notSupported = false;
        this.shortName = shortName;
      } else {
        this.notSupported = true;
      }
    },
    getJumpLink() {
      if (!this.selectedUniversity) {
        this.$message.error('请先选择学校');
        return false;
      }
      this.$http({
        url: '/api/v1/login/getSSOConfig?shortName=' + this.shortName,
        method: 'GET'
      }).then(res => {
        if (res.returnvalue.type == 'redirect') {
          let url = res.returnvalue.url;

          let href = window.location.href;
          // let href = 'https://localhost:8088/admin/#/login';
          let idx = this.findStr(href, '/', 2);
          let sharp = href.indexOf('#');
          let subHref = href.slice(idx+1, sharp);

          url = url.replace('#URI', subHref + '%23' + this.$route.path);

          console.log(url)
          window.location.assign(url);
        }
      });
    },
    findStr(str,cha,num) {
      var x = str.indexOf(cha);
      for(var i=0;i<num;i++){
        x=str.indexOf(cha,x+1);
      }
      return x;
    },
    getLogin() {
      if (localStorage.getItem('token')) {
        return false;
      }
      let href = window.location.href;
      console.log(href)
      let params;
      if (href.indexOf('fair=') == -1) {
        return false;
      } else {
        params = href.slice(href.indexOf('?'));
        this.doAllLogin(params);
      }
    },
    doAllLogin(params) {
      this.allLogin(params).then(res => {
        console.log(res);
        if (res.cxajaxrc == 0) {
          this.$message.success('登录成功！');
          console.log(res.headers);
          const token = res.returnvalue;
          localStorage.setItem('token', token);
          location.reload();
        } else {
          // this.$message.error(res.cxajaxerrmsg)
        }
      })
    },
    tabClick(tab) {
      console.log(tab.name)
      if (tab.name != 'login') {
        this.findPassword = false;
      }
      if (tab.name == 'uniteLogin') {
        this.getCustomers();
      }
    }
  },
  created() {
    if (localStorage.getItem('password')) {
      this.username = localStorage.getItem('username');
      this.password = localStorage.getItem('password');
    }
    this.newUser = new NewUser();
  },
  mounted() {
    if (this.activeTab == 'uniteLogin') {
      this.getCustomers();
    }
  },
  mixins: [loginMixin],
}
</script>

<style lang="scss" scoped>
  .login-menu {
    display: flex;
    justify-content: flex-end;
    text-align: center;
  }
  .login-dialog {
    /deep/ .el-tab-pane {
      margin-top: 20px;
      > * {
        margin-bottom: 30px;
      }
      .check-pass {
        margin: 0;
        margin-bottom: 0;
      }
    }
    /deep/ a {
      text-decoration: none;
    }
    .find {
      color: #000;
    }
    /deep/ .el-dialog__close {
      color: #666;
    }
  }
  .logout {
    color: #fff;
    margin-left: 30px;
  }
  .logout:hover {
    font-weight: bold;
  }
  .hover-middle {
    margin: 15px 0;
    padding: 5px;
  }
  .hover-middle a {
    display: block;
  }
  .hover-bottom {
    text-align: end;
  }
  .password-actions {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 5px;
  }
  .to-register {
    text-align: center;
    font-size: 1rem;
  }
  .to-register a {
    color: #43aad3;
  }
  .choose-school {
    margin-bottom: 10px;
  }
  .start-verify {
    margin-bottom: 180px;
  }
  .not-supported {
    text-align: center;
    line-height: 1rem;
    color: red;
  }
  .pass-login {
    color: #43aad3;
  }
  /deep/ .el-dialog__header {
    background-color: #fff;
  }
</style>